export const durationToTimer = (duration: number): string => {
  const hours = Math.trunc(duration / 3600)
  const minutes = Math.trunc((duration % 3600) / 60)
  const seconds = duration % 60

  return `${hours ? `${hours}:` : ''}${minutes ? (minutes < 10 ? `0${minutes}` : minutes) : '00'}:${
    seconds ? (seconds < 10 ? `0${seconds}` : seconds) : '00'
  }`
}

export function formatDuration(duration: number): string {
  const hours = Math.trunc(duration / 3600)
  const minutes = Math.trunc((duration % 3600) / 60)
  const seconds = duration % 60

  const hoursString = hours ? `${hours}h` : ''
  const minutesString = minutes ? `${minutes}mn` : hoursString ? '0mn' : ''
  const secondsString = seconds ? `${seconds}s` : '0s'

  return `${hoursString}${minutesString}${secondsString}`
}
