import { useEffect, useRef, useState } from 'react'
import type { IPicture } from '../../../types/pictures'

import { SVGIcon } from '../SVGIcon'
import { theme } from '../../../styles'
import { durationToTimer } from '../../../helpers/durationToTimer'
import { useIntersection } from '../../../hook/useIntersection'
import { PictureElementList } from '../PictureElementList'

export interface IPreviewVideo {
  url: string
  pictures: IPicture
  duration?: number | string
  triggerType?: string
  isVideoVertical?: boolean
  withIcon?: boolean
  isActive?: boolean
}

export function PreviewVideo({
  url,
  pictures,
  duration,
  triggerType = 'hover',
  isVideoVertical,
  withIcon = true,
  isActive = false,
}: IPreviewVideo): JSX.Element {
  const [previewEnabled, setPreviewEnabled] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const itemRef = useRef<HTMLDivElement>(null)

  const isScroll = triggerType === 'scroll'
  const { inView } = useIntersection({
    ref: isScroll ? itemRef.current : null,
    onEnter: () => {
      if (isScroll) setPreviewEnabled(true)
    },
    onLeave: () => {
      if (isScroll) setPreviewEnabled(false)
    },
    options: {
      threshold: 1,
    },
  })

  const handleMouseEvent = (isHovered: boolean) => {
    if (triggerType === 'hover') setPreviewEnabled(isHovered)
  }

  // making sure scroll observer use the ref we want
  useEffect(() => {
    setIsLoaded(true)
  }, [])

  const isPreviewOn =
    url && isLoaded && (isActive || (previewEnabled && (isScroll ? inView : true)))

  return (
    <>
      <div
        className="PreviewVideo"
        ref={itemRef}
        onMouseEnter={() => handleMouseEvent(true)}
        onMouseLeave={() => handleMouseEvent(false)}
      >
        <div className="PreviewVideo__Placeholder">
          <PictureElementList {...pictures} aria-hidden />
        </div>
        <div className="PreviewVideo__Player__Wrapper">
          {isPreviewOn ? (
            <video
              className="PreviewVideo__Player"
              src={url}
              controls={false}
              playsInline
              autoPlay
              muted
              loop
            ></video>
          ) : null}
        </div>
      </div>
      {withIcon ? (
        <div className="PreviewVideo__Icon">
          <SVGIcon name="play-circle" secondaryColor={theme.cssVars.white} size="37px" />
        </div>
      ) : null}
      {duration && Number(duration) > 0 ? (
        <div className="PreviewVideo__Duration">{durationToTimer(Number(duration))}</div>
      ) : null}

      <style jsx>{`
        .PreviewVideo__Placeholder {
          padding-top: ${isVideoVertical ? '177.77%' : '56.25%'};
        }
      `}</style>
      <style jsx>{`
        .PreviewVideo__Player__Wrapper {
          opacity: ${isPreviewOn ? '1' : '0'};
        }
      `}</style>

      <style jsx>{`
        .PreviewVideo {
          position: relative;
        }

        .PreviewVideo,
        .PreviewVideo__Placeholder {
          display: block;
          width: 100%;
          height: 100%;
        }

        .PreviewVideo :global(picture),
        .PreviewVideo :global(img) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .PreviewVideo :global(.PictureElementList__PlaceHolder) {
          z-index: 0;
        }

        .PreviewVideo__Player__Wrapper {
          transition: opacity 0.5s ease 0s;
        }

        .PreviewVideo__Player {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .PreviewVideo__Player__Filter {
          background-color: ${theme.cssVars.deepBlue};
          opacity: 0.5;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
        }

        .PreviewVideo__Icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        .PreviewVideo__Duration {
          position: absolute;
          bottom: 30px;
          left: 25px;
          background-color: ${theme.cssVars.white};
          padding: 3px 6px 0;
          border-radius: 50px;
          font-size: 13px;
          font-weight: 600;
          line-height: 145%;
        }
      `}</style>
    </>
  )
}
