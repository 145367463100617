import type { IPicture } from '../../../types/pictures'
import React from 'react'
import { Button } from '../../atoms/Button'
import { BackgroundImage } from '../../atoms/BackgroundImage'
import { mixins, theme } from '../../../styles'
import { SVGIcon } from '../../atoms/SVGIcon'

interface IPlayerUnavailable {
  url: string
  pictures: IPicture
  title: string
  subtitle: string
  cta: string
}

export function PlayerUnavailable({ url, pictures, title, subtitle, cta }: IPlayerUnavailable) {
  return (
    <>
      <div className="PlayerUnavailable flex justify-center items-center">
        <BackgroundImage pictures={pictures} />
        <div className="PlayerUnavailable__Content flex flex-column justify-center items-center">
          <span className="PlayerUnavailable__Content__Title">{title}</span>
          <p className="PlayerUnavailable__Content__Subtitle">{subtitle}</p>
          <Button
            backgroundColor={theme.cssVars.white}
            textColor={theme.cssVars.darkBlue}
            href={url}
          >
            {cta}
            <SVGIcon name="arrow" primaryColor={theme.cssVars.darkBlue} />
          </Button>
        </div>
      </div>

      <style jsx>{`
        .PlayerUnavailable {
          position: relative;
          padding-top: 56.25%;
          width: 100%;
          background-color: ${theme.colors.darkestBlue};
        }

        .PlayerUnavailable :global(.BackgroundImage) {
          z-index: 0;
        }

        .PlayerUnavailable :global(img) {
          z-index: 0;
        }

        .PlayerUnavailable :global(.BackgroundImage__Filter) {
          display: block;
          z-index: 1;
          background-image: linear-gradient(${theme.colors.deepBlue}90, ${theme.colors.deepBlue}90);
        }

        .PlayerUnavailable__Content {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          gap: 20px;
        }

        .PlayerUnavailable__Content__Title {
          color: ${theme.cssVars.focusBlue};
          text-align: center;
          margin: 0;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 145%; /* 18.85px */
        }

        .PlayerUnavailable__Content__Subtitle {
          color: ${theme.cssVars.white};
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 146%; /* 21.9px */
        }

        .PlayerUnavailable__Content :global(.Button) {
          width: inherit;
        }

        .PlayerUnavailable__Content :global(svg) {
          margin-bottom: 2px;
          margin-left: 5px;
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .PlayerUnavailable__Content {
            max-width: 342px;
          }
          .PlayerUnavailable__Content__Title {
            font-size: 17px;
            line-height: 145%; /* 24.65px */
          }

          .PlayerUnavailable__Content__Subtitle {
            font-size: 22px;
            font-weight: 300;
            line-height: 140%; /* 30.8px */
          }
        }
      `}</style>
    </>
  )
}
